import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style/main.scss'
import HeaderBar from '@/components/HeaderBar'
import FooterBar from '@/components/FooterBar'
import {Popover} from "element-ui";
Vue.component('HeaderBar', HeaderBar)
Vue.component('FooterBar', FooterBar)
Vue.config.productionTip = false
Vue.use(Popover);


if(!window.sessionStorage.getItem('cash')){
  window.location.href = window.location.href+'?a='+new Date().getTime() 
  window.sessionStorage.setItem('cash',true)
}
const IE11RouterFix = {
  methods: {
    hashChangeHandler: function() {
      this.$router.push(window.location.hash.substring(1, window.location.hash.length))
    },
    isIE11: function() {
      return !!window.MSInputMethodContext && !!document.documentMode
    }
  },
  mounted: function() {
    if (this.isIE11()) {
      window.addEventListener('hashchange', this.hashChangeHandler)
    }
  },
  destroyed: function() {
    if (this.isIE11()) {
      window.removeEventListener('hashchange', this.hashChangeHandler)
    }
  }
}
new Vue({
  router,
  mixins: [IE11RouterFix],
  render: h => h(App)
}).$mount('#app')
