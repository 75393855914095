
<template>
  <header
    class="header"
    :style="{position:fixed?'fixed':'absolute'}"
  >
    <div class="logo">
      <router-link
        to="/"
        @click.native="linkTo('/')"
      >
        <img
          v-if="white"
          src="@/assets/logo-white.png"
        >
        <img
          v-else
          src="@/assets/logo-black.png"
        >
      </router-link>
    </div>
    <div
      class="menu"
      :class="{'black':!white}"
    >
      <router-link
        class="menu-item"
        to="/"
      >首页</router-link>
      <router-link
        class="menu-item"
        :class="{'active':swiperActiveIndex===0}"
        to="/qiye"
      >掌玩网络</router-link>
      <router-link
        class="menu-item"
        :class="{'active':swiperActiveIndex===1}"
        :to="{name:'Yewu'}"
      >业务介绍</router-link>
      <router-link
        class="menu-item"
        :class="{'active':swiperActiveIndex===2}"
        :to="{name:'Rongyu'}"
      >企业荣誉</router-link>
      <router-link
        class="menu-item"
        :class="{'active':swiperActiveIndex===3}"
        :to="{name:'Join'}"
      >企业招聘</router-link>
      <router-link
        class="menu-item"
        :class="{'active':swiperActiveIndex===4}"
        :to="{name:'Rencai'}"
      >人才培养</router-link>
      <router-link
        class="menu-item"
        :class="{'active':swiperActiveIndex===5}"
        :to="{name:'Fazhan'}"
      >发展历程</router-link>
      <router-link
        class="menu-item"
        :class="{'active':swiperActiveIndex===6}"
        :to="{name:'Wenhua'}"
      >文化展示</router-link>
      <!-- <router-link
        class="menu-item"
        :class="{'active':swiperActiveIndex===6}"
        :to="{name:'Hezuo'}"
      >合作伙伴</router-link> -->
    </div>
    <div
      v-if="this.$route.hash === '#home'"
      class="loading"
      :class="{show:loading}"
    ></div>
  </header>
</template>
<script>
export default {
  props: {
    swiperActiveIndex: Number,
    white: Boolean,
    fixed: {
      type: Boolean,
      defalt: true
    }
  },

  data() {
    return {
      menuArr: ['企业文化', '业务介绍', '企业荣耀', '企业招聘', '人才招聘', '人才培养', '发展历程', '合作伙伴'],
      loading: this.$route.hash === '#home'
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 250)
  },
  methods: {
    linkTo(url) {
      console.log(url)
    }
  }
}
</script>
<style lang="scss" scoped>
header {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10;
  padding-top: 20px;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  .logo {
    width: 193px;
    height: 55px;
    border: none;
    img {
      outline: none;
      border: 0px;
    }
  }
  .menu {
    color: #fdfdfd;
    transition: all 0.3s;
    opacity: 1;
    &.hide {
      transform: translateY(-200px);
      opacity: 0;
    }
    &.black {
      color: #333333;
      .menu-item {
        color: #333333;
        &::after {
          background: #333333;
        }
      }
    }
    .menu-item {
      padding: 0 10px;
      align-items: center;
      color: #fdfdfd;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      &::after {
        content: ' ';
        background: #fdfdfd;
        height: 2px;
        width: 0.1%;
        position: absolute;
        left: 50%;
        bottom: -10px;
        transition: all 0.2s ease-in-out;
        opacity: 0;
      }
      &:hover::after {
        left: 0%;
        width: 100%;
        opacity: 0.9;
      }
      &.active {
        color: #ed2908;
        &::after {
          bottom: -11px;
          height: 3px;
          left: 0%;
          opacity: 0.9;
          width: 100%;
          background: #ed2908;
        }
      }
    }
  }
}
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  top: 0;
  left: 0;
  clip-path: circle(0% at 0% 100%);
  transition: all 0.5s;
  &.show {
    opacity: 1;
    clip-path: circle(150% at 0 100%);
  }
}
</style>