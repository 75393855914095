import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home2')
  },
  {
    path: '/qiye',
    name: 'Qiye',
    component: () => import('../views/qiye')
  },
  {
    path: '/yewu',
    name: 'Yewu',
    component: () => import('../views/yewu')
  },
  {
    path: '/join',
    name: 'Join',
    component: () => import('../views/join')
  },
  {
    path: '/rencai',
    name: 'Rencai',
    component: () => import('../views/rencai')
  },
  {
    path: '/rencaidetail/:id',
    name: 'Rencaidetail',
    component: () => import('../views/rencaidetail')
  },
  {
    path: '/fazhan',
    name: 'Fazhan',
    component: () => import('../views/fazhan')
  },
  {
    path: '/wenhua',
    name: 'Wenhua',
    component: () => import('../views/wenhua')
  },
  {
    path: '/hezuo',
    name: 'Hezuo',
    component: () => import('../views/hezuo')
  },
  {
    path: '/rongyu',
    name: 'Rongyu',
    component: () => import('../views/rongyu')
  },
  {
    path: '/Home2',
    name: 'Home2',
    component: () => import('../views/Home2')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
